@import '@hh.ru/magritte-ui/breakpoints';

.text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 12px;
}

.card-container {
    position: relative;
    height: 172px;

    .screen-gt-xs({
        height: 146px;
    });

    .screen-gt-s({
        height: 228px;
    });
}

.card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
