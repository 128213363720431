@import '@hh.ru/magritte-ui/tokens';

.tabs-wrapper {
    padding: 0 24px;

    // Убрать после добавления баджа в секондари-табы магрита
    /* stylelint-disable max-nesting-depth */
    button[data-qa='tab-has-news'] {
        span[data-qa='tab-postfix'] {
            position: relative;
            overflow: visible;

            &::after {
                position: absolute;
                content: '';
                top: -5px;
                right: -11px;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: @magritte-color-background-negative;
            }
        }
    }
    /* stylelint-enable max-nesting-depth */
}

.title-wrapper {
    padding: 15px 24px;
}

.title-wrapper,
.tabs-wrapper {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 3px;
        left: 0;
        width: 100%;
        background: @magritte-color-stroke-neutral;
    }
}

.widget-tab-content {
    display: flex;
    flex-direction: column;
    height: 288px;
    border-bottom-left-radius: @magritte-static-border-radius-600;
    border-bottom-right-radius: @magritte-static-border-radius-600;
    overflow-y: scroll;
    overflow-x: hidden;

    // Убрать после выработки общего решения
    // HH-250616
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: color-mix(in srgb, @magritte-color-background-constant-secondary 32%, transparent);
        border-radius: 24px;
    }
}

.saved-searches-container,
.saved-searches-placeholder-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.saved-searches-content {
    height: 100%;
}

.saved-searches-button-content {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.saved-searches-title {
    padding: 18px 24px;
}

.saved-searches-placeholder-content {
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
